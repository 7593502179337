@import '../../variables.scss';

.LateralMenu__Container {
    width: 250px;
    height: calc(100% - 50px);
    background-color: #cdcdcd;
    color: $text-color;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    top: 50px;
    transition: all 0.3s ease-in-out;
    z-index: 5;


    a {
        color: $text-color;
    }

    ol, ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        img {
            width: 90%;
            max-width: 200px;
        }
        padding: 10px 20px;
        border-bottom: 1px solid #666666;

        &:hover {
            cursor: pointer;
        }

        &:last-child{
            border-bottom: none;
        }

        &.LateralMenu__Center {
            text-align: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;

            p {
                width: 100%;
            }

            &:hover {
                cursor: inherit;
            }
        }
    }

    @media only screen and (max-width: 686px) {
        left: -250px;
        background-color: #ffffff;
        box-shadow: 0 0 0 1px rgba(63,63,68,.05), 0 1px 3px 0 rgba(63,63,68,.15);

        &.open-menu {
            left: 0;
            background-color: #cdcdcd;
        }
    }
}