@import url(https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:200,400,400i,700);
@import url(https://fonts.googleapis.com/css?family=Merriweather&display=swap);
html {
  scroll-behavior: smooth; }

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  font-size: 16px; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

p {
  font-size: 16px; }

h1 {
  color: #000;
  font-weight: bold;
  font-size: 1.8em; }

h2 {
  color: #055d5e;
  font-size: 1.5em; }

h3 {
  font-size: 1.2em; }

h4 {
  font-size: 1em; }

h5 {
  font-size: 0.8em; }

h3, h4, h5, h6 {
  color: #000; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Merriweather", serif;
  text-transform: uppercase;
  letter-spacing: 2px; }

.ac-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%; }

.ac-form-field {
  width: 100%;
  margin: 10px 0; }

.desktop-half {
  width: 45%; }

.ac-form-field label {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 105%; }

.ac-form-field input[type="text"],
.ac-form-field input[type="email"],
.ac-form-field input[type="password"],
.ac-form-field input.ac-force-format {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid #444;
  font-size: 1em;
  font-family: "Poppins", sans-serif; }

.ac-form-field input[type="text"]:focus,
.ac-form-field input[type="email"]:focus,
.ac-form-field input[type="password"]:focus,
.ac-form-field input.ac-force-format:focus {
  outline: none;
  border-bottom: 2px solid #055d5e; }

.ac-form-field textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid #444;
  background: none;
  font-size: 1em;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box; }

.ac-form-field textarea:focus {
  outline: none;
  border: 2px solid #055d5e; }

.ac-form-field select {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid #444;
  height: 32pt;
  font-size: 1em; }

.ac-form-field select:focus {
  outline: none;
  border-bottom: 2px solid #055d5e; }

.ac-form-title {
  width: 100%; }

.ac-form-sub-title {
  width: 100%; }

@media only screen and (max-width: 686px) {
  .desktop-half {
    width: 100%; } }

.Login__Container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  background-color: #000000; }
  .Login__Container img {
    width: 90%;
    max-width: 500px;
    margin-bottom: 50px; }
  .Login__Container .Login__Form-Container {
    width: 90%;
    max-width: 500px;
    margin-bottom: 50px; }

.Card__Container {
  width: 100%;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  border-radius: 3px;
  background-color: white;
  padding: 20px;
  box-sizing: border-box; }

.Button {
  display: block;
  width: auto;
  padding: 10px 15px;
  border: none;
  box-sizing: border-box;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 5px 0; }
  .Button:hover {
    cursor: pointer; }

.Center {
  margin: 5px auto; }

.Big {
  font-size: 140%;
  padding: 10px 15px; }

.Small {
  font-size: 60%;
  padding: 5px 10px; }

.Button__Primary {
  background-color: #055d5e;
  color: #fff;
  border: 1px solid #055d5e; }
  .Button__Primary:hover {
    border: 1px solid #055d5e;
    background-color: #fff;
    color: #055d5e; }
  .Button__Primary:focus {
    outline: none; }

.Button__Secondary {
  background-color: #000;
  color: #fff;
  border: 1px solid #000; }
  .Button__Secondary:hover {
    border: 1px solid #000;
    background-color: #fff;
    color: #000; }

.Button__Danger {
  background-color: red;
  color: #fff;
  border: 1px solid red; }
  .Button__Danger:hover {
    border: 1px solid red;
    background-color: #fff;
    color: red; }

.PopUpMessage__Container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center; }
  .PopUpMessage__Container .PopUpMessage__Box {
    width: 50%;
    max-width: 800px;
    max-height: 90vh;
    background-color: white;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
    .PopUpMessage__Container .PopUpMessage__Box p {
      margin: 0; }
    .PopUpMessage__Container .PopUpMessage__Box .PopUpMessage__Title {
      width: 100%;
      padding: 10px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #666;
      font-weight: bold; }
    .PopUpMessage__Container .PopUpMessage__Box .fa-times {
      float: right; }
      .PopUpMessage__Container .PopUpMessage__Box .fa-times:hover {
        cursor: pointer; }
    .PopUpMessage__Container .PopUpMessage__Box .PopUpMessage__Text {
      width: 100%;
      overflow-y: scroll;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      padding: 20px;
      box-sizing: border-box; }
  @media only screen and (max-width: 686px) {
    .PopUpMessage__Container .PopUpMessage__Box {
      width: 90%; } }

.PopUpMessage__Hidden {
  display: none; }

.Manual__Container {
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden; }
  .Manual__Container .Manual__Main {
    width: calc(100% - 250px);
    height: calc(100% - 50px);
    position: absolute;
    left: 250px;
    top: 50px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 10px 30px;
    overflow-y: scroll;
    scroll-behavior: smooth; }
    .Manual__Container .Manual__Main .Manual__Page {
      box-sizing: border-box;
      min-height: 100%;
      width: 100%;
      padding: 2rem 0;
      border-bottom: 3px solid #055d5e; }
      .Manual__Container .Manual__Main .Manual__Page .Manual_Info {
        width: 100%;
        display: flex;
        flex-direction: column; }
        .Manual__Container .Manual__Main .Manual__Page .Manual_Info img {
          width: 100%;
          max-width: 1024px;
          margin: 0 auto; }
        .Manual__Container .Manual__Main .Manual__Page .Manual_Info a {
          text-decoration: none; }
          .Manual__Container .Manual__Main .Manual__Page .Manual_Info a .Button {
            margin: 0 auto; }
        .Manual__Container .Manual__Main .Manual__Page .Manual_Info h3,
        .Manual__Container .Manual__Main .Manual__Page .Manual_Info p {
          text-align: center; }
    @media only screen and (max-width: 686px) {
      .Manual__Container .Manual__Main {
        left: 0;
        width: 100%;
        padding: 10px 20px; }
        .Manual__Container .Manual__Main.open-menu {
          left: 250px; } }

.NavBar__Container {
  width: 100%;
  height: 50px;
  background-color: #000;
  color: white;
  padding: 10px 10%;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: center; }
  .NavBar__Container .NavBar__Logo {
    height: 100%;
    width: 100%;
    max-width: 1024px;
    position: relative;
    left: 0;
    top: 0; }
    .NavBar__Container .NavBar__Logo img {
      height: 100%;
      position: absolute;
      left: calc(50% - 25px); }
    .NavBar__Container .NavBar__Logo .NavBar__Hamburger {
      display: none; }
    @media only screen and (max-width: 686px) {
      .NavBar__Container .NavBar__Logo .NavBar__Hamburger {
        display: block;
        position: absolute;
        left: 0;
        font-size: 1.3rem; } }

.LateralMenu__Container {
  width: 250px;
  height: calc(100% - 50px);
  background-color: #cdcdcd;
  color: #444;
  overflow-y: scroll;
  position: absolute;
  left: 0;
  top: 50px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 5; }
  .LateralMenu__Container a {
    color: #444; }
  .LateralMenu__Container ol, .LateralMenu__Container ul {
    margin: 0;
    padding: 0;
    list-style: none; }
  .LateralMenu__Container li {
    padding: 10px 20px;
    border-bottom: 1px solid #666666; }
    .LateralMenu__Container li img {
      width: 90%;
      max-width: 200px; }
    .LateralMenu__Container li:hover {
      cursor: pointer; }
    .LateralMenu__Container li:last-child {
      border-bottom: none; }
    .LateralMenu__Container li.LateralMenu__Center {
      text-align: center;
      display: flex;
      flex-flow: row wrap;
      justify-content: center; }
      .LateralMenu__Container li.LateralMenu__Center p {
        width: 100%; }
      .LateralMenu__Container li.LateralMenu__Center:hover {
        cursor: inherit; }
  @media only screen and (max-width: 686px) {
    .LateralMenu__Container {
      left: -250px;
      background-color: #ffffff;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15); }
      .LateralMenu__Container.open-menu {
        left: 0;
        background-color: #cdcdcd; } }


