@import './variables.scss';

html {
  scroll-behavior: smooth;
}

//Config
body {
  margin: 0;
  font-family: $text-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  font-size: 16px;
}

h1 {
  color: $secondary-color;
  font-weight: bold;
  font-size: 1.8em;
}

h2 {
  color: $main-color;
  font-size: 1.5em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.8em;
}

h3, h4, h5, h6 {
  color: $secondary-color;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $title-font;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.ac-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%
}

.ac-form-field {
  width: 100%;
  margin: 10px 0;
}

.desktop-half {
  width: 45%;
}

.ac-form-field label {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 105%;
}

.ac-form-field input[type="text"],
.ac-form-field input[type="email"],
.ac-form-field input[type="password"],
.ac-form-field input.ac-force-format {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid $text-color;
  font-size: 1em;
  font-family: $text-font;
}

.ac-form-field input[type="text"]:focus,
.ac-form-field input[type="email"]:focus,
.ac-form-field input[type="password"]:focus,
.ac-form-field input.ac-force-format:focus {
  outline: none;
  border-bottom: 2px solid $main-color;
}

.ac-form-field textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 2px solid $text-color;
  background: none;
  font-size: 1em;
  font-family: $text-font;
  box-sizing: border-box;
}

.ac-form-field textarea:focus {
  outline: none;
  border: 2px solid $main-color;
}

.ac-form-field select {
  width: 100%;
  padding: 10px 0;
  margin-top: 5px;
  border: none;
  background: none;
  border-bottom: 2px solid $text-color;
  height: 32pt;
  font-size: 1em;
}

.ac-form-field select:focus {
  outline: none;
  border-bottom: 2px solid $main-color;
}

.ac-form-title {
  width: 100%;
}

.ac-form-sub-title {
  width: 100%;
}

@media only screen and (max-width: 686px) {
  .desktop-half {
    width: 100%;
  }
}