.Login__Container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #000000;

    img {
        width: 90%;
        max-width: 500px;
        margin-bottom: 50px;
    }

    .Login__Form-Container {
        width: 90%;
        max-width: 500px;
        margin-bottom: 50px;
    }
}