@import '../../variables.scss';

.NavBar__Container {
    width: 100%;
    height: 50px;
    background-color: $secondary-color;
    color: invert($color: $secondary-color);
    padding: 10px 10%;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .NavBar__Logo {
        height: 100%;
        width: 100%;
        max-width: 1024px;
        position: relative;
        left: 0;
        top: 0;

        img {
            height: 100%;
            position: absolute;
            left: calc(50% - 25px);
        }

        .NavBar__Hamburger {
            display: none;
        }
    
        @media only screen and (max-width: 686px) {
            .NavBar__Hamburger {
                display: block;
                position: absolute;
                left: 0;
                font-size: 1.3rem;
            }
        }
    }
}