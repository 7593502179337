@import '../../variables.scss';

.Button {
  display: block;
  width: auto;
  padding: 10px 15px;
  border: none;
  box-sizing: border-box;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 3px;
  margin: 5px 0;

  &:hover {
    cursor: pointer;
  }
}

.Center {
  margin: 5px auto;
}

.Big {
  font-size: 140%;
  padding: 10px 15px;
}

.Small {
  font-size: 60%;
  padding: 5px 10px;
}

.Button__Primary {
  background-color: $main-color;
  color: #fff;
  border: 1px solid $main-color;

  &:hover {
    border: 1px solid $main-color;
    background-color: #fff;
    color: $main-color;
  }

  &:focus {
    outline: none;
  }
}

.Button__Secondary {
  background-color: $secondary-color;
  color: #fff;
  border: 1px solid $secondary-color;

  &:hover {
    border: 1px solid $secondary-color;
    background-color: #fff;
    color: $secondary-color;
  }
}

.Button__Danger {
  background-color: red;
  color: #fff;
  border: 1px solid red;

  &:hover {
    border: 1px solid red;
    background-color: #fff;
    color: red;
  }
}