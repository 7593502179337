@import '../../variables.scss';

.Manual__Container {
  width: 100vw;
  height: 100vh;
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden;

  .Manual__Main {
    width: calc(100% - 250px);
    height: calc(100% - 50px);
    position: absolute;
    left: 250px;
    top: 50px;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 10px 30px;
    overflow-y: scroll;
    scroll-behavior: smooth;

    .Manual__Page {
      box-sizing: border-box;
      min-height: 100%;
      width: 100%;
      padding: 2rem 0;
      border-bottom: 3px solid $main-color;

      .Manual_Info {
        width: 100%;
        display: flex;
        flex-direction: column;

        img {
          width: 100%;
          max-width: 1024px;
          margin: 0 auto;
        }

        a {
          text-decoration: none;

          .Button {
            margin: 0 auto;
          }
        }

        h3,
        p {
          text-align: center;
        }
      }
    }

    @media only screen and (max-width: 686px) {
      left: 0;
      width: 100%;
      padding: 10px 20px;

      &.open-menu {
        left: 250px;
      }
    }
  }
}
